<template>
  <v-container>
    <v-stepper v-model="e6" vertical>
      <v-stepper-step :complete="e6 > 1" step="1">
        Pickup Details
        <small>PickDate, Pickup Location</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card flat class="mb-12 pa-3" min-height="100px">
          <div class="display-1 font-weight-thin">Pickup Details</div>
          <v-row class="ma-4">
            <v-col cols="12" sm="12" md="6">
              <v-checkbox
                v-model="outpickup"
                label="Pickup Outside Fulfillment Center"
              ></v-checkbox>
              <v-divider class="mb-7" />
              <vuetify-google-autocomplete
                id="map"
                classname="form-control"
                placeholder="Pick Up Address"
                v-on:placechanged="setPickupLocation"
                country="ng"
                shaped
                filled
                v-if="outpickup"
                autocomplete="off"
              >
              </vuetify-google-autocomplete>
              <v-checkbox
                v-model="TodayPickUp"
                label="Pickup Today"
              ></v-checkbox>
              <v-divider class="mb-7" />
              <div v-if="!TodayPickUp">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      filled
                      shaped
                      v-model="pickDate"
                      label="Pickup Date"
                      append-icon="mdi-calendar"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="#9f09d7"
                    ref="picker"
                    v-model="pickDate"
                    year-icon="mdi-calendar-blank"
                    prev-icon="mdi-skip-previous"
                    next-icon="mdi-skip-next"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time"
                      label="Pickup Time"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      filled
                      shaped
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu2"
                    v-model="time"
                    full-width
                    @click:minute="$refs.menu.save(time)"
                    @change="saveTime(time)"
                  ></v-time-picker>
                </v-menu>
                {{ PickDate }}
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-btn color="primary" @click="nextForm(2)"> Continue </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 2" step="2">
        Sender Details
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-card flat class="mb-12 pa-3" min-height="100px">
          <div class="display-1 font-weight-thin">Sender Details</div>
          <v-row class="ma-4">
            <v-col cols="12" sm="12" md="5">
              <v-text-field
                v-model="firstName"
                shaped
                label="FirstName"
                filled
                dense
              ></v-text-field>
              <v-text-field
                v-model="lastName"
                shaped
                label="LastName"
                filled
                dense
              ></v-text-field>
              <v-text-field
                shaped
                label="email Optional"
                filled
                v-model="email"
                dense
              ></v-text-field>
              <VuePhoneNumberInput
                default-country-code="NG"
                v-model="unformatedphoneNumber"
                @update="results = $event"
                filled
                size="lg"
              />
            </v-col>
          </v-row>
        </v-card>
        <v-btn @click="e6 = 1" text> Back </v-btn>
        <v-btn color="primary" @click="nextForm(3)"> Continue </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 3" step="3">
        Parcel Details
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-card class="mb-12" min-height="100px">
          <div class="display-1 font-weight-thin">Parcel Details</div>
          <v-row class="ma-4">
            <v-col cols="12" sm="6">
              <v-radio-group v-model="sendType" row>
                <v-radio label="Document" value="Document"></v-radio>
                <v-radio label="Parcel" value="Parcel"></v-radio>
              </v-radio-group>
              <v-divider />
              <v-radio-group v-model="deliveryType" row>
                <v-radio label="Van" value="Van"></v-radio>
                <v-radio label="Bike" value="Bike"></v-radio>
                <v-radio label="Truck" value="Truck"></v-radio>
                <v-radio label="Plane" value="Plane"></v-radio>
              </v-radio-group>
              <v-divider class="mb-5" />
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Note for Rider
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-textarea
                      filled
                      auto-grow
                      label="Note for Rider"
                      rows="4"
                      row-height="30"
                      shaped
                      v-model="note"
                    ></v-textarea>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-divider class="mt-5 mb-5" />
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    shaped
                    label="Package Valuation"
                    v-model="userPackageValuation"
                    filled
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card elevation="2" class="pa-2 mt-2">
                    {{ DisplayCurrency(userPackageValuation) }}
                  </v-card>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="d-flex" cols="8" sm="6">
                  <h3>Kilogram :</h3>
                </v-col>

                <v-col class="d-flex" cols="4" sm="6">
                  <v-text-field
                    v-model="weight"
                    shaped
                    label="Weight in Kilogram"
                    filled
                    dense
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <vuetify-google-autocomplete
                id="mape"
                classname="form-control"
                placeholder="Delivery Address"
                v-on:placechanged="setDeliveryLocation"
                country="ng"
                shaped
                :value="city"
                filled
                type="text"
                autocomplete="off"
              >
              </vuetify-google-autocomplete>
              <v-text-field
                shaped
                label="Contact Person"
                v-model="contactPerson"
                filled
                dense
              ></v-text-field>
              <VuePhoneNumberInput
                default-country-code="NG"
                v-model="unformatedphoneNumber1"
                @update="results1 = $event"
                filled
                class="mb-4"
                size="lg"
              />
              <v-select
                :items="CategoryOption"
                filled
                v-model="packageCategory"
                shaped
                label="Category"
              ></v-select>
              <v-select
                :items="['Doorstep Delivery', 'Station Pickup']"
                filled
                v-model="deliveryMethod"
                shaped
                label="Delivery Method"
              ></v-select>
            </v-col>
          </v-row>
          <div v-if="Address.length > 0">
            <v-data-table
              :headers="dessertHeaders"
              :items="Address"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              item-key="name"
              show-expand
              class="elevation-1"
              :hide-default-footer="true"
            >
              <template v-slot:item.packageCategory="{ item }">
                <span>
                  <v-btn @click="deleteParcel(item)" fab x-small dark>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </span>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-simple-table dark class="mb-5">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Contact Person</th>
                          <th class="text-left">QTY</th>
                          <th class="text-left">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(i, index) in item.parcelItems" :key="index">
                          <td>{{ i.name }}</td>
                          <td>{{ i.qty }}</td>
                          <td>{{ i.price }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </td>
              </template>
            </v-data-table>
          </div>
          <div class="text-end ma-5">
            <v-btn
              class="ma-5"
              @click="addMoreParcel()"
              rounded
              color="pink"
              dark
            >
              Add More Parcel
            </v-btn>
          </div>
        </v-card>
        <v-btn @click="e6 = 2" text> Back </v-btn>
        <v-btn color="primary" @click="nextForm(4)"> Continue </v-btn>
      </v-stepper-content>

      <v-stepper-step step="4"> Generate Estimated Price </v-stepper-step>
      <v-stepper-content step="4">
        <v-row>
          <v-col cols="12" sm="12">
            <v-card flat class="mb-12" min-height="100px">
              <div v-if="Address.length > 0">
                <v-data-table
                  :headers="dessertHeaders"
                  :items="Address"
                  :single-expand="singleExpand"
                  :expanded.sync="expanded"
                  item-key="name"
                  show-expand
                  class="elevation-1"
                  :hide-default-footer="true"
                >
                  <template v-slot:item.cashDelivery="{ item }">
                    <span>
                      {{ DisplayCurrency(item.cashDelivery) }}
                    </span>
                  </template>
                  <template v-slot:item.userPackageValuation="{ item }">
                    <span>
                      {{ DisplayCurrency(item.userPackageValuation) }}
                    </span>
                  </template>
                  <template v-slot:item.packageCategory="{ item }">
                    <span>
                      <v-btn @click="deleteParcel(item)" fab x-small dark>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </span>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-simple-table dark class="mb-5">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Contact Person</th>
                              <th class="text-left">QTY</th>
                              <th class="text-left">Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(i, index) in item.parcelItems"
                              :key="index"
                            >
                              <td>{{ i.name }}</td>
                              <td>{{ i.qty }}</td>
                              <td>{{ i.price }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </td>
                  </template>
                </v-data-table>
              </div>
              <v-btn
                @click="getEstimatedAndDeliveryAddress()"
                rounded
                color="pink"
                dark
                class="ma-5"
              >
                Get Estimated Price
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
        <v-btn @click="e6 = 3"> Back </v-btn>
      </v-stepper-content>
    </v-stepper>
    <v-dialog persistent v-model="dialog" width="650">
      <v-card v-if="estimatedPriceResponse">
        <v-card-title class="text-h5 grey lighten-2">
          Estimated Price Details
          <v-spacer />
          Total Distance: {{ estimatedPriceResponse.distance }}
        </v-card-title>

        <v-card-text>
          <v-simple-table dark>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Address</th>
                  <th class="text-left">Km</th>
                  <th class="text-left">Fees Km</th>
                  <th class="text-left">Weight</th>
                  <th class="text-left">Weight Fees</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(
                    item, index
                  ) in estimatedPriceResponse.deliveryDetails"
                  :key="index"
                >
                  <td>{{ item.Street }}</td>
                  <td>{{ item.distanceKm }}</td>
                  <td>
                    {{ DisplayCurrency(item.usedFeePerKm) }}
                  </td>
                  <td>{{ item.weight }}</td>
                  <td>{{ DisplayCurrency(item.weightMeasurementFees) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <div class="display-1 font-weight-thin">
            {{ DisplayCurrency(estimatedPriceResponse.amount) }}
          </div>
          <v-spacer></v-spacer>
          <v-select
            :items="PaymentMethods"
            label="Select Payment Method"
            v-model="PaymentMethod"
            dense
            shaped
            filled
          ></v-select>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="pink" @click="ContinuePayment">Continue</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import dayjs from "dayjs";
import currencyFormatter from "currency-formatter";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: {
    VuePhoneNumberInput,
  },
  data: () => ({
    parcelItems: [],
    results1: null,
    results: null,
    unformatedphoneNumber: "",
    unformatedphoneNumber1: "",
    DeliveryLocation: "",
    outpickup: false,
    sendType: "Parcel",
    packageCategory: "",
    deliveryType: "Bike",
    fitToBox: true,
    CategoryOption: ["FMCG", "perishable", "non-perishable", "cake"],
    userPackageValuation: "",
    pickDate: new Date(dayjs()).toISOString().substr(0, 10),
    menu: false,
    time: null,
    TodayPickUp: true,
    menu2: false,
    modal2: false,
    PickDate: new Date(dayjs()).toISOString().substr(0, 10),
    note: "",
    phoneNumber: "",
    mobile: "",
    firstName: "",
    lastName: "",
    contactPerson: "",
    email: "",
    Address: [],
    city: "",
    pickUpLocation: "",
    dialog: false,
    selectedWeightMeasurement: "Kilogram",
    weight: "0",
    PaymentMethods: ["POS", "Cash", "BankTransfer"],
    PaymentMethod: "",
    deliveryMethod: "",
    e6: 1,
    page: 1,
    returnType: "Accept Return",
    parcelReturn: false,
    itemName: "",
    itemQty: "",
    itemPrice: "",
    cashDelivery: 0,
    AddWeight: false,
    dessertHeaders: [
      {
        text: "Contact Person",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Delivery", value: "Street" },
      { text: "Delivery Type", value: "deliveryType" },
      { text: "mobile", value: "mobile" },
      { text: "Valuation", value: "userPackageValuation" },
      { text: "Return Policy", value: "parcelReturn" },
      { text: "Cash Back", value: "cashDelivery" },
      { text: "Action", value: "packageCategory" },
      { text: "", value: "data-table-expand" },
    ],
  }),
  methods: {
    setSender(payload) {
      this.firstName = payload.firstName;
      this.lastName = payload.lastName;
      this.unformatedphoneNumber = payload.phoneNumber;
      this.email = payload.email;
    },
    deleteParcelItem(payload) {
      let newArr = [];
      this.parcelItems.forEach((element) => {
        if (element.updated != payload.updated) {
          newArr.push(element);
        }
      });
      this.parcelItems = newArr;

      this.userPackageValuation =
        this.userPackageValuation - parseFloat(payload.price) * payload.qty;
    },
    addParcelItem() {
      if (this.itemName == "" || this.itemQty == "" || this.itemPrice == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "itemName, itemQty and  itemPrice"
        );
        return;
      }
      let payload = {
        name: this.itemName,
        qty: this.itemQty,
        price: this.itemPrice,
        updated: Date.now(),
      };
      this.cashDelivery =
        parseFloat(this.cashDelivery) +
        parseFloat(this.itemPrice) * this.itemQty;
      this.userPackageValuation = this.cashDelivery;
      this.parcelItems.push(payload);
      this.itemName = "";
      this.itemQty = "";
      this.itemPrice = "";
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    nextForm(payload) {
      if (payload == 2) {
        if (this.PickDate == "") {
          this.$store.commit("setSnackbar", true);
          this.$store.commit(
            "setServerMessage",
            "please Fill out all the require field"
          );
        } else this.e6 = 2;
      }
      if (payload == 3) {
        if (
          this.firstName == "" ||
          this.lastName == "" ||
          this.phoneNumber == ""
        ) {
          this.$store.commit("setSnackbar", true);
          this.$store.commit(
            "setServerMessage",
            "please Fill out all the require field"
          );
        } else this.e6 = 3;
      }
      if (payload == 4) {
        if (
          this.mobile != "" &&
          this.contactPerson != "" &&
          this.DeliveryLocation != "" &&
          this.selectedWeightMeasurement != "" &&
          this.weight != "" &&
          this.deliveryType != ""
        ) {
          let returnTypes = null;
          let cashDelivery = null;
          if (this.parcelReturn) {
            returnTypes = this.returnType;
            if (this.returnType == "Accept Return or Collect Payment") {
              if (this.cashDelivery > 0) {
                cashDelivery = this.cashDelivery;
              } else {
                this.$store.commit("setSnackbar", true);
                this.$store.commit(
                  "setServerMessage",
                  "Enter The Amount to be collected from the receiver"
                );
                return;
              }
            }
          }
          let payload = {
            parcelReturn: this.parcelReturn,
            returnType: returnTypes,
            cashDelivery: cashDelivery,
            mobile: this.mobile,
            name: this.contactPerson,
            weight: this.weight,
            weightMeasurement: this.selectedWeightMeasurement,
            note: this.note,
            packageCategory: this.packageCategory,
            userPackageValuation: this.userPackageValuation,
            sendType: this.sendType,
            deliveryType: this.deliveryType,
            fitToBox: this.fitToBox,
            deliveryMethod: this.deliveryMethod,
            updated: Date.now(),
            parcelItems: this.parcelItems,
            ...this.DeliveryLocation,
          };
          this.Address = [...this.Address, payload];
          this.e6 = 4;
          this.mobile = "";
          this.contactPerson = "";
          this.DeliveryLocation = "";
          this.city = "";
          this.userPackageValuation = "";
          this.parcelItems = [];
        } else {
          if (this.Address.length > 0) {
            this.e6 = 4;
          } else {
            this.$store.commit("setSnackbar", true);
            this.$store.commit(
              "setServerMessage",
              "Please Fill out all the require field"
            );
          }
        }
      }
    },
    deleteParcel(payload) {
      let newArr = [];
      this.Address.forEach((element) => {
        if (element.updated != payload.updated) {
          newArr.push(element);
        }
        this.Address = newArr;
      });
    },
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "NGN",
      });
    },
    addMoreParcel() {
      if (
        this.mobile == "" ||
        this.contactPerson == "" ||
        this.DeliveryLocation == "" ||
        this.selectedWeightMeasurement == "" ||
        this.weight == "" ||
        this.deliveryType == ""
      ) {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "Please Fill out all the require field"
        );
      } else {
        let returnTypes = null;
        let cashDelivery = null;
        if (this.parcelReturn) {
          returnTypes = this.returnType;
          if (this.returnType == "Accept Return or Collect Payment") {
            if (this.cashDelivery > 0) {
              cashDelivery = this.cashDelivery;
            } else {
              this.$store.commit("setSnackbar", true);
              this.$store.commit(
                "setServerMessage",
                "Enter The Amount to be collected from the receiver"
              );
              return;
            }
          }
        }
        let payload = {
          parcelReturn: this.parcelReturn,
          returnType: returnTypes,
          cashDelivery: cashDelivery,
          mobile: this.mobile,
          name: this.contactPerson,
          weight: this.weight,
          weightMeasurement: this.selectedWeightMeasurement,
          note: this.note,
          packageCategory: this.packageCategory,
          userPackageValuation: this.userPackageValuation,
          sendType: this.sendType,
          deliveryType: this.deliveryType,
          fitToBox: this.fitToBox,
          updated: Date.now(),
          deliveryMethod: this.deliveryMethod,
          ...this.DeliveryLocation,
          parcelItems: this.parcelItems,
        };
        this.Address = [...this.Address, payload];
        this.mobile = "";
        this.contactPerson = "";
        this.DeliveryLocation = "";
        this.city = "";
        this.userPackageValuation = "";
        this.parcelItems = [];
      }
    },
    getEstimatedAndDeliveryAddress() {
      if (this.Address.length == 0) {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "Please Fill out all the require field"
        );
      } else {
        //get the estimated price
        this.getEstamatedPrice();
      }
    },

    saveTime(payload) {
      let time = dayjs(this.pickDate).format("ddd, MMM DD, YYYY,");
      this.PickDate = `${time} ${payload}`;
    },
    setPickupLocation(payload) {
      let payloaded = {
        City: payload.locality,
        longitude: payload.longitude,
        latitude: payload.latitude,
        Street: payload.name,
      };
      this.pickUpLocation = payloaded;
    },
    setDeliveryLocation(payload) {
      let payloaded = {
        City: payload.locality,
        longitude: payload.longitude,
        latitude: payload.latitude,
        Street: payload.name,
      };
      this.DeliveryLocation = payloaded;
      this.city = `${payload.name} ${payload.locality}`;
    },

    getEstamatedPrice() {
      let payload = {
        deliveryDetails: this.Address,
        offlineSenderDetails: {
          phoneNumber: this.phoneNumber,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
        },
        pickupLocation: this.pickUpLocation,
        pickDate: this.PickDate,
        token: this.getToken,
      };
      this.$store.dispatch("officePackagePickUpEstimatedPrice", payload);
    },

    ContinuePayment() {
      if (this.PaymentMethod == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "Select Payment Method");
        return;
      }

      let payload = {
        token: this.getToken,
        packageId: this.estimatedPriceResponse.packageId,
        PaymentMethod: this.PaymentMethod,
        route: this.$router,
      };
      this.$store.dispatch("officeParcelPayment", payload);
    },
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
    estimatedPriceResponse() {
      return this.$store.getters.estimatedPrice;
    },
    weightMeasurement() {
      return this.$store.getters.weightMeasurement;
    },
    offlineCu() {
      return this.$store.getters.Users;
    },
  },
  watch: {
    results(val) {
      if (val) {
        if (val.formattedNumber) {
          this.phoneNumber = val.formattedNumber;
        }
      }
    },
    results1(val) {
      if (val) {
        if (val.formattedNumber) {
          this.mobile = val.formattedNumber;
        }
      }
    },
    estimatedPriceResponse: function (val) {
      if (val != null) {
        console.log(val);
        this.dialog = true;
      }
    },
  },
};
</script>